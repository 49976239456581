/* File: /src/index.css */

 * {
  margin: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
  margin: 0;
  background: rgb(232,251,255);
  background: linear-gradient(90deg, rgba(232,251,255,1) 0%, rgba(238,252,255,1) 50%, rgba(232,251,255,1) 100%);
}

body, html {
  width: 100vw;
  overflow-x: hidden;
}